import React, { useEffect, forwardRef } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import ClientDashboard from "./components/ClientDashboard/ClientDashboard";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { alreadyAuthenticated } from "./api/auth/actions";
import Login from "./components/Auth/Login";
import GenerateCreds from "./components/Admin/Admin";
import { getLocalStorage } from "./api/localStorage/localStorage";
import roles, { permissionAccess, permissions } from "./utils/roles";
import agencyTypes from "./utils/agencyType";
import AccountManager from "./components/AccountManager/AccountManager";
import SelectChannel from "./components/Admin/Analytics/analytics";
import AdminPop from "./components/Admin/AdminPop";
import HDMClientDashboard from "./components/HDMClientDashboard/HDMClientDashboard";
import GeneratePdf from "./components/Common/GeneratePdf";
import HDMAccountManager from "./components/HDMAccountManager/HDMAccountManager";
import BrandPage from "./components/AccountManager/BrandPage/Brandpage";
import { toggleSnackbar } from "./api/Global/slice";
import AnalyticDashboard from "./components/Analytics/AnalyticsDashboard";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./utils/queryClient";
import bpAmRoutePaths from "./pages/(bp-am)/constants/bpAmRoutePaths";
import BPAMRootRouter from "./pages/(bp-am)/Router";
import LDAMRootRouter from "./pages/ld-am/Router";
import bpClientRoutePaths from "./pages/(bp-client)/constants/bpClientRoutePath";
import BPClientRootRouter from "./pages/(bp-client)/Router";
import BPBrandPage from "./components/AccountManager/BPBrandPage";
import ldAmRoutePaths from "./pages/ld-am/constants/ldAmRoutePaths";
import ldClientRoutePaths from "./pages/ld-client/constants/ldClientRoutePaths";
import LDClientRootRouter from "./pages/ld-client/Router";
import hdmAmRoutePaths from "./pages/hdm-am/constants/hdmAmRoutePaths";
import HDMAMRootRouter from "./pages/hdm-am/Router";
import hdmClientRoutePaths from "./pages/hdm-client/constants/hdmClientRoutePaths";
import HDMClientRootRouter from "./pages/hdm-client/Router";
import AdminPageRouter from "./pages/admin/Router";
import adminRoutePaths from "./pages/admin/constants/adminRoutePaths";
import GoogleAuthRedirect from "./pages/common/GoogleAuthRedirect";
import FacebookAuthRedirect from "./pages/common/FacebookAuthRedirect";
import { getPermissionsAPI } from "./api/auth/saga";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const App = ({ isAuthnticated }) => {
  // debugger;
  console.log("## in app", isAuthnticated);
  const dispatch = useDispatch();
  const showFlashmessage = useSelector(
    (store) => store.global.showFlashmessage
  );
  const flashMessage = useSelector((store) => store.global.flashMessage);
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("accessToken")) {
        const permissions = await getPermissionsAPI();
        dispatch(alreadyAuthenticated(permissions));
      }
    })();
    getLocalStorage("role") === roles.ACCOUNT_MANAGER &&
      //getLocalStorage("agencyId") == 1 &&
      getLocalStorage("agencyType") === agencyTypes.LINEAR &&
      !getLocalStorage("isNextClicked") &&
      localStorage.clear();
    localStorage.getItem("accessToken") && dispatch(alreadyAuthenticated());
  }, []);
  const handleClose = () => {
    dispatch(toggleSnackbar());
  };
  // const [searchParams, setSearchParams] = useSearchParams();
  const permissionsFromBackend = useSelector(
    (state) => state.auth.permissions || []
  );
  useEffect(() => {
    const querySearchParams = new URLSearchParams(
      window.location.href.split("?")[1]
    );
    if (querySearchParams.get("code")) {
      console.log("Code : ", querySearchParams.get("code"));
    }
  }, []);
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          className="snackbar"
          style={{ zIndex: 199 }}
          open={showFlashmessage}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={flashMessage.severity}
            sx={{ width: "100%" }}
          >
            {flashMessage.message}
          </Alert>
        </Snackbar>
      </Stack>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {isAuthnticated && localStorage.getItem("accessToken") ? (
              <>
                {permissionsFromBackend.includes(
                  permissions.ANALYTICS_DASHBOARD.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.ANALYTICS_DASHBOARD.concat(
                      permissionAccess.VIEW
                    )
                ) && (
                  <Route
                    path="/analytic-dashboard"
                    element={<AnalyticDashboard />}
                  />
                )}
                <Route
                  path="/google-auth/callback"
                  element={
                    <GoogleAuthRedirect
                      agencyType={getLocalStorage("agencyType")}
                    />
                  }
                />
                <Route
                  path="/facebook-auth/callback"
                  element={
                    <FacebookAuthRedirect
                      agencyType={getLocalStorage("agencyType")}
                    />
                  }
                />
                {permissionsFromBackend.includes(
                  permissions.BRAND_PAGE.concat(permissionAccess.EXECUTE) ||
                    permissions.BRAND_PAGE.concat(permissionAccess.VIEW)
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.LINEAR && (
                    //(getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) && (
                    <Route path="ld-am/brands" element={<BrandPage />} />
                  )}
                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.HDM && (
                    //(getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && (
                    <Route
                      path={`${hdmClientRoutePaths.root}/*`}
                      element={<HDMClientRootRouter />}
                    />
                  )}

                {permissionsFromBackend.includes(
                  permissions.BRAND_PAGE.concat(permissionAccess.EXECUTE) ||
                    permissions.BRAND_PAGE.concat(permissionAccess.VIEW)
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.HDM && (
                    //(getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) && (
                    <Route
                      path="/hdm-am/brands"
                      element={<HDMAccountManager />}
                    />
                  )}

                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.HDM && (
                    <Route
                      path={`${hdmAmRoutePaths.root}/*`}
                      element={<HDMAMRootRouter />}
                    />
                  )}

                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.LINEAR && (
                    <Route
                      path={`${ldClientRoutePaths.root}/*`}
                      element={<LDClientRootRouter />}
                    />
                  )}
                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_ADMIN_TABLE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_ADMIN_TABLE.concat(
                      permissionAccess.VIEW
                    )
                ) && (
                  <Route
                    path={`${adminRoutePaths.root}/*`}
                    // path="*"
                    element={<AdminPageRouter />}
                  />
                )}
                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") === agencyTypes.LINEAR && (
                    <Route
                      path={`${ldAmRoutePaths.root}/*`}
                      element={<LDAMRootRouter />}
                    />
                  )}

                {permissionsFromBackend.includes(
                  permissions.BRAND_PAGE.concat(permissionAccess.EXECUTE) ||
                    permissions.BRAND_PAGE.concat(permissionAccess.VIEW)
                ) &&
                  getLocalStorage("agencyType") ===
                    agencyTypes.BLACK_PROPELLER && (
                    <Route path={"/bp-am/brands"} element={<BPBrandPage />} />
                  )}
                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") ===
                    agencyTypes.BLACK_PROPELLER && (
                    <Route
                      path={`${bpAmRoutePaths.root}/*`}
                      element={<BPAMRootRouter />}
                    />
                  )}
                {permissionsFromBackend.includes(
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.EXECUTE
                  ) ||
                    permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                      permissionAccess.VIEW
                    )
                ) &&
                  getLocalStorage("agencyType") ===
                    agencyTypes.BLACK_PROPELLER && (
                    <Route
                      path={`${bpClientRoutePaths.root}/*`}
                      element={<BPClientRootRouter />}
                    />
                  )}
                {/*<Route
                  path="*"
                  element={
                    permissionsFromBackend.includes(
                      permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                        permissionAccess.EXECUTE
                      ) ||
                        permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                          permissionAccess.VIEW
                        )
                    ) &&
                    getLocalStorage("agencyType") === agencyTypes.LINEAR ? (
                      //(getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12) ? (
                      <BrandPage />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                            permissionAccess.VIEW
                          )
                      ) &&
                      getLocalStorage("agencyType") === agencyTypes.LINEAR ? (
                      //(getLocalStorage("agencyId") == 1 || getLocalStorage("agencyId") == 12 ) ? (
                      <ClientDashboard />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                            permissionAccess.VIEW
                          )
                      ) && getLocalStorage("agencyType") === agencyTypes.HDM ? (
                      //(getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) ? (
                      <HDMClientDashboard />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                            permissionAccess.VIEW
                          )
                      ) && getLocalStorage("agencyType") === agencyTypes.HDM ? (
                      //(getLocalStorage("agencyId") == 2 || getLocalStorage("agencyId") == 13) &&
                      <HDMAccountManager />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                            permissionAccess.VIEW
                          )
                      ) &&
                      getLocalStorage("agencyType") ===
                        agencyTypes.BLACK_PROPELLER ? (
                      <Navigate to="/bp-client/homepage" replace />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                            permissionAccess.VIEW
                          )
                      ) &&
                      getLocalStorage("agencyType") ===
                        agencyTypes.BLACK_PROPELLER ? (
                      <Navigate to="/bp-am/brands" replace />
                    ) : permissionsFromBackend.includes(
                        permissions.LOGIN_REDIRECT_ADMIN_TABLE.concat(
                          permissionAccess.EXECUTE
                        ) ||
                          permissions.LOGIN_REDIRECT_ADMIN_TABLE.concat(
                            permissionAccess.VIEW
                          )
                      ) ? (
                      <Navigate
                        to={`${adminRoutePaths.root}/${adminRoutePaths.sub.client}`}
                        replace
                      />
                    ) : (
                      <Login />
                    )
                  }
                />*/}
              </>
            ) : (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
      {/* <p>Site under maintenance.... Please come back after sometime.</p>  */}
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthnticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(App);
